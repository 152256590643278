import { Bars3BottomLeftIcon } from "@heroicons/react/20/solid";
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, Styles, SuggestBox } from "@reactik/components";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { TypeNames } from "../type-signature.js";
import { DataEditorProps } from "./Editable.js";
import { EditableSchemaProperty } from "./EditableSchemaProperty.js";

function makeTypeOptions() {
    const types: string[] = Object.values(TypeNames)
    const options = [...types]
    for (const type of types) {
        options.push(type + '[]');
    }
    types.sort();
    return options;
}

const TYPE_OPTIONS = makeTypeOptions();

export function PropertyEditor({ value, onChange, onCancel, onSave }: DataEditorProps<EditableSchemaProperty>) {
    const [isModalOpen, setModalOpen] = useState(false);

    if (!value) return null;

    const onNameChange = (text: string) => {
        onChange({ ...value, name: text })
    }

    const onTypeChange = (text: string) => {
        onChange({ ...value, type: text })
    }

    const onDescriptionChange = (text?: string) => {
        if (text !== undefined) {
            onChange({ ...value, description: text }, true);
        }
        setModalOpen(false);
    }

    return (
        <div className="flex pr-2 items-center">
            <div className="flex-1">
                <PropertyNameEditor value={value.name} onChange={onNameChange} onCancel={onCancel} onSave={onSave} />
            </div>
            <div className="font-semibold px-1">:</div>
            <div className="flex-1">
                <PropertyTypeEditor value={value.type} onChange={onTypeChange} onCancel={onCancel} onSave={onSave} />
            </div>
            <div>
                <button onClick={() => setModalOpen(true)} className="flex items-center text-gray-400 hover:text-gray-800" title="Edit description">
                    <Bars3BottomLeftIcon className="h-4 w-4" />
                </button>
                <EditDescriptionModal value={value.description} isOpen={isModalOpen} onClose={onDescriptionChange} />
            </div>
        </div>
    )

}


export function PropertyNameEditor({ value, onChange, onCancel, onSave }: DataEditorProps<string>) {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ref.current?.focus();
    }, [])

    const onKeyUp = (e: React.KeyboardEvent) => {
        switch (e.key) {
            case "Enter":
                onSave?.();
                break;
            case "Escape":
                onCancel?.();
                break;
        }
    }

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    }

    return (
        <input onKeyUp={onKeyUp} ref={ref} value={value} onChange={_onChange} className={Styles.INPUT_UNSTYLED} style={{ fontSize: "inherit", width: "", display: "inline" }} />
    )
}

function PropertyTypeEditor({ value, onChange, onCancel, onSave }: DataEditorProps<string>) {
    const onBlur = () => {
        onSave?.();
    }
    const onKeyDown = (e: KeyboardEvent, isOpen: boolean) => {
        if (!isOpen) {
            if (e.key === 'Enter') {
                onSave?.();
            } else if (e.key === 'Escape') {
                onCancel?.();
            }
        }
    }
    return (
        <SuggestBox strategy="fixed" style={{ paddingRight: '28px', width: "" }}
            className={Styles.INPUT_UNSTYLED}
            options={TYPE_OPTIONS}
            value={value || ''}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            popupClass="schema-type-suggest-popup"
        />
    )
}

interface EditDescriptionModalProps {
    value: string | undefined;
    isOpen: boolean;
    onClose: (text?: string) => void;
}
function EditDescriptionModal({ value, isOpen, onClose }: EditDescriptionModalProps) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalTitle>Edit description</ModalTitle>
            <EditDescriptionModalForm value={value} onSave={onClose} />
        </Modal>
    )
}

interface EditDescriptionModalFormProps {
    value: string | undefined;
    onSave: (text: string) => void;
}
function EditDescriptionModalForm({ value, onSave }: EditDescriptionModalFormProps) {
    const ref = useRef<HTMLTextAreaElement>(null);
    const [currentValue, setCurrentValue] = useState(value || '');
    useEffect(() => {
        ref.current && ref.current.focus();
    }, [ref.current]);
    return (
        <>
            <ModalBody className="h-max">
                <textarea ref={ref} className="w-full h-full" value={currentValue} onChange={(e) => setCurrentValue(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => onSave(currentValue)}>Save Changes</Button>
            </ModalFooter>
        </>
    )
}
