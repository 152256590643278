import clsx from 'clsx';
import { useState } from 'react';

import { IndigoBadge } from './Badge';
import { Styles } from './styles';

interface InputListProps {
    value?: string[];
    onChange: (value: string[]) => void;
    className?: string;
    allowSpaces?: boolean;
    delimiters?: string; // space and , by default
}
export function InputList({ value = [], onChange, className, delimiters = ", " }: InputListProps) {
    const [text, setText] = useState<string>('');

    const _onChange = (ev: any) => {
        setText(ev.target.value)
    }
    const onBlur = (ev: any) => {
        const v = ev.target.value;
        if (v && v.trim()) {
            onChange([...value, v.trim()])
            setText('')
        }
    }
    const onKeyDown = (ev: any) => {
        const v = ev.target.value;
        const isEmpty = !v.trim();
        const key = ev.key;
        if (key === 'Enter' || delimiters.indexOf(key) > -1) {
            ev.preventDefault();
            if (value && !isEmpty) {
                onChange([...value, v.trim()])
                setText('')
            }
        } else if (key === 'Backspace' && isEmpty) {
            if (value && value.length > 0) {
                value.pop();
                onChange([...value])
            }
        }
    }

    const _onClick = (index: any): void => {
        if (value && value.length > 0) {
            value.splice(index, 1);
            onChange([...value]);
        }
    };

    return (
        <div className={clsx('w-full', Styles.INPUT, "flex items-center space-x-1 mr-2 pl-2 overflow-auto", className)}>
            {
                value.map((v, index) =>
                    <IndigoBadge key={index} onClick={() => _onClick(index)}>{v}</IndigoBadge>
                )
            }
            <input
                className={Styles.INPUT_UNSTYLED}
                type='text'
                value={text}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={_onChange}
            />
        </div>
    )
}