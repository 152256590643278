import { CSSProperties, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
import clsx from 'clsx'

const COMBO_DEFAULT_STYLE = "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

function createFilter<T>(query: string, textOf: ((option: T) => string) | (keyof T & string)) {
    if (typeof textOf === "string") {
        return (option: T) => {
            return String(option[textOf]).toLowerCase().includes(query.toLowerCase())
        }
    } else {
        return (option: any) => {
            return textOf(option).toLowerCase().includes(query.toLowerCase())
        }
    }
}

interface SuggestBoxProps<T> {
    strategy?: "fixed" | "absolute"
    options: T[] | undefined
    value?: T
    onChange: (option: T) => void
    onBlur?: () => void
    className?: string
    style?: CSSProperties
    // compare for equality by field or comoparision function
    by?: (keyof T & string) | ((a: T, z: T) => boolean),
    // the text on which to filter (passed as a field name or a function)
    textOf?: (keyof T & string) | ((option: T) => string)
    labelOf?: (option: T) => string | ReactElement
    placeholder?: ReactNode
    onKeyDown?: (ev: KeyboardEvent, isOpen: boolean) => void
    popupClass?: string
}
export function SuggestBox<T>({ options = [],
    strategy = "absolute",
    textOf = String,
    onKeyDown,
    labelOf = (option) => String(option),
    value, onChange, className, style,
    onBlur,
    popupClass,
    by }: SuggestBoxProps<T>) {
    const ref = useRef<HTMLElement | null>(null);

    const [query, setQuery] = useState('')
    const [selectedOption, setSelectedOption] = useState<T | undefined>(value)

    const filtered = query === '' ? options : options.filter(createFilter<T>(query, textOf))

    const _onChange = (value: T) => {
        setSelectedOption(value)
        onChange(value)
    }

    useEffect(() => {
        if (ref.current && onKeyDown) {
            const el = ref.current;
            const listener = (e: KeyboardEvent) => {
                onKeyDown(e, ref.current?.getAttribute('data-headlessui-state') === 'open');
            };
            el.addEventListener('keydown', listener)
            return () => {
                el.removeEventListener('keydown', listener)
            }
        }
    }, [onKeyDown]);

    const _textOf = typeof textOf === 'string' ? (option: T) => option[textOf] as string : textOf;

    return (
        <Combobox ref={ref} as="div" value={selectedOption} onChange={_onChange} by={by as any}>
            <div className="relative">
                <ComboboxInput
                    onBlur={onBlur}
                    style={style}
                    className={className ? className : COMBO_DEFAULT_STYLE}
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={_textOf}
                //                    onKeyDown={(e) => console.log('=========', e.which)}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                {filtered.length > 0 && (
                    <ComboboxOptions anchor="bottom" style={{ width: ref.current ? ref.current.offsetWidth + 'px' : 'auto' }}
                        className={clsx(strategy, "z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm", popupClass)}>
                        {filtered.map((option) => (
                            <ComboboxOption
                                key={_textOf(option)}
                                value={option}
                                className={({ active }) =>
                                    clsx(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={clsx('block truncate', selected && 'font-semibold')}>{labelOf(option)}</span>

                                        {selected && (
                                            <span
                                                className={clsx(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                )}
            </div>
        </Combobox>
    )
}