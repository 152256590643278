import { useEffect, useState } from 'react';

import { useUserSession } from '@/session/UserSession';
import { ContentObjectTypeRef } from '@becomposable/common';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Button, SelectBox } from '@reactik/components';
import { SchemaSource } from '@reactik/schema-editor';
import { schemaSourceToTypeRef } from './schema-source';
import { LinkSlashIcon } from '@heroicons/react/20/solid';

interface ExternalSchemaToolbarProps {
    source?: SchemaSource;
    onTypeSelectionChange: (type: ContentObjectTypeRef | undefined) => void;
}
export function ExternalSchemaToolbar({ source, onTypeSelectionChange }: ExternalSchemaToolbarProps) {
    const { client } = useUserSession();
    const [types, setTypes] = useState<ContentObjectTypeRef[]>([]);
    const [typeRef, setTypeRef] = useState<ContentObjectTypeRef | undefined>();
    useEffect(() => {
        setTypeRef(schemaSourceToTypeRef(source));
    }, [source])
    const onSelectType = (type?: ContentObjectTypeRef | null) => {
        setTypeRef(type || undefined);
        onTypeSelectionChange && onTypeSelectionChange(type || undefined);
    }
    useEffect(() => {
        client.types.list({ query: { name: undefined } }).then(setTypes);
    }, []);
    return (
        <div className="text-sm border-b border-b-gray-200 pb-2 flex items-center justify-between gap-x-1">
            <SelectBox onChange={onSelectType}
                ClearIcon={LinkSlashIcon}
                clearTitle="Unlink the result schema from the selected type"
                value={typeRef}
                isClearable
                by="id"
                filterBy="name"
                optionLabel={type => type.name}
                options={types} className="w-full h-10" placeholder="Select a Content Type" />
            <Button isDisabled={!typeRef} size='sm' variant="ghost" className="rounded-full !p-2" title="Open content type in new window"><ArrowTopRightOnSquareIcon className="h-4 w-4"
                onClick={() => window.open(`/store/types/${typeRef?.id}#schema`, "_blank")} /></Button>
        </div>
    )
}
