import { ExternalSchemaToolbar } from "@/modules/studio/components/ExternalSchemaToolbar";
import { getSchemaUri, resolveSchema, typeRefToSchemaSource } from "@/modules/studio/components/schema-source";
import { contentToJSON, jsonToContent } from "@/modules/studio/utils/test-data";
import { useUserSession } from "@/session/UserSession";
import { ContentObjectTypeRef, SchemaRef } from "@becomposable/common";
import { json } from "@codemirror/lang-json";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { CodeMirrorEditor, EditorApi } from "@reactik/codemirror";
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, PinkBadge, Switch, ToastFn, useToast } from "@reactik/components";
import { useCompositeState, useFlag } from "@reactik/hooks";
import { ManagedSchema, SchemaEditor } from "@reactik/schema-editor";
import { basicSetup } from "codemirror";
import { JSONSchema4 } from "json-schema";
import { useEffect, useMemo, useRef, useState } from "react";
import PlaygroundState from "./PlaygroundState";

const codemirrorExtensions = [basicSetup, json()]

enum CloseEvent {
    Cancel,
    Apply
}

interface SchemaOverrideEditorProps {
}
export function SchemaOverrideEditor({ }: SchemaOverrideEditorProps) {
    const { off, isOn, on } = useFlag();
    const playgroundState = useCompositeState(PlaygroundState);
    const [status, setStatus] = useState<"default" | "overridden">("default");
    const onClose = (event?: CloseEvent) => {
        const cause = event || CloseEvent.Cancel;
        if (cause == CloseEvent.Apply) {
            setStatus("overridden");
        }
        off();
    }
    const removeOverride = () => {
        playgroundState.result_schema = undefined;
        setStatus("default");
    }
    return (
        <div>
            <div className="h-10 py-2 my-4 border-b border-solid border-b-gray-200 dark:border-b-gray-500">
                <div className="flex items-center justify-between">
                    <div className="flex gap-x-2 items-baseline">
                        <div className="text-md font-semibold">Result Schema</div>
                        <div>
                            <PinkBadge>
                                <div>{status}</div>
                                {status === "overridden" && <Button variant="ghost" onClick={removeOverride} className="!pl-1 !py-0 pr-0"><XMarkIcon className="w-4 h-4" /></Button>}
                            </PinkBadge>

                        </div>
                    </div>
                    <div>
                        <Button variant="ghost" onClick={on}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="text-sm">Use the <i>Edit</i> button above to view or override the schema used for the response.</div>
            <OverrideSchemaModal isOpen={isOn} onClose={onClose} />
        </div>
    )
}

interface OverrideSchemaModalProps {
    schema?: JSONSchema4 | SchemaRef | undefined | null;
    isOpen: boolean;
    onClose: (event?: CloseEvent) => unknown;
    onSchemaChange?: (schema: JSONSchema4 | SchemaRef | undefined | null) => unknown;
}
function OverrideSchemaModal({ schema, isOpen, onClose }: OverrideSchemaModalProps) {
    // we only render SchemaEditorBody when dialog is open
    // this way we reset the state of the editor each time the dialog is opened
    // so it will be initialized with payloadState.result_schema
    return (
        <Modal isOpen={isOpen} onClose={onClose} className='min-w-[50vw]'>
            <ModalTitle>Override result schema</ModalTitle>
            {isOpen && <SchemaEditorBody onClose={onClose} />}
        </Modal>
    )
}

interface SchemaEditorBodyProps {
    onClose: (event?: CloseEvent) => unknown;
}
function SchemaEditorBody({ onClose }: SchemaEditorBodyProps) {
    const toast = useToast();
    const { toggle: toggleRawJson, isOn: isEditJsonOn } = useFlag();
    const editorRef = useRef<EditorApi | undefined>(undefined);
    const { client } = useUserSession();
    const playgroundState = useCompositeState(PlaygroundState);
    const [managedSchema, setManagedSchema] = useState<ManagedSchema | undefined>();
    const [showSelectType, setShowSelectType] = useState(false);

    const jsonValue = useMemo(() => {
        return jsonToContent(managedSchema?.schema);
    }, [managedSchema])

    useEffect(() => {
        const updateSchema = (schema: ManagedSchema) => {
            const clone = schema.clone();
            setManagedSchema(clone);
        }
        const result_schema = playgroundState.result_schema || playgroundState.interaction.result_schema;
        if (result_schema?.$uri) {
            resolveSchema(client, result_schema.$uri).then((result) => {
                setManagedSchema(new ManagedSchema(result.schema)
                    .withSource(result.name, result.uri)
                    .withChangeListener(updateSchema)
                );
            });
        } else {
            setManagedSchema(new ManagedSchema(result_schema)
                .withChangeListener(updateSchema)
            );
        }
    }, [playgroundState.interaction, playgroundState.result_schema]);

    const onTypeSelectionChange = (typeRef: ContentObjectTypeRef | undefined) => {
        const ref = typeRef ? { $uri: getSchemaUri(typeRef), name: typeRef.name } : undefined;
        playgroundState.result_schema = ref;
        if (managedSchema) {
            const clone = managedSchema.clone();
            clone.source = typeRefToSchemaSource(typeRef);
            setManagedSchema(clone);
        }
    }

    const applyOverride = () => {
        isEditJsonOn && saveJsonEditor(managedSchema, editorRef, toast);
        playgroundState.result_schema = managedSchema && !managedSchema.isEmpty ? managedSchema.schema : undefined;
        window.setTimeout(() => onClose(CloseEvent.Apply), 100);
        //onClose(CloseEvent.Apply);
    }

    const toggleSchemaEditor = () => {
        isEditJsonOn && saveJsonEditor(managedSchema, editorRef, toast);
        toggleRawJson();
    }

    let externalSchemaToolbar: React.ReactNode = null;
    const isTypeLinked = !!managedSchema?.source?.uri;
    if (isTypeLinked || showSelectType) {
        externalSchemaToolbar = <ExternalSchemaToolbar source={managedSchema?.source} onTypeSelectionChange={onTypeSelectionChange} />
    } else {
        externalSchemaToolbar = <div className='py-2'>
            <Switch value={false} onChange={(checked) => setShowSelectType(checked)}>Use a Content Type</Switch>
        </div>
    }

    return managedSchema && (
        <>
            <ModalBody className='p-4 pt-0 overflow-y-auto min-h-[70vh] max-h-[70vh]'>
                <div>
                    {externalSchemaToolbar}
                    <div className="flex gap-x-2 pt-2">
                        <Button variant={isEditJsonOn ? "ghost" : "soft"} onClick={toggleSchemaEditor}>Schema Editor</Button>
                        <Button variant={!isEditJsonOn ? "ghost" : "soft"} onClick={toggleSchemaEditor}>JSON Editor</Button>
                    </div>
                    {isEditJsonOn ?
                        <CodeMirrorEditor value={jsonValue} extensions={codemirrorExtensions} editorRef={editorRef} />
                        :
                        <SchemaEditor schema={managedSchema} />
                    }
                </div>
            </ModalBody>
            <ModalFooter showDivider>
                <Button variant="primary" onClick={applyOverride}>Apply Override</Button>
            </ModalFooter>
        </>
    )
}


function saveJsonEditor(managedSchema: ManagedSchema | undefined, editorRef: React.MutableRefObject<EditorApi | undefined>, toast: ToastFn) {
    const value = editorRef.current?.getValue().trim();
    if (value) {
        try {
            managedSchema?.replaceSchema(contentToJSON(value));
        } catch (err: any) {
            toast({
                status: 'error',
                title: 'Invalid JSON Schema',
                description: err.message,
                duration: 5000
            })
        }
    } else {
        managedSchema?.replaceSchema(null);
    }
}
