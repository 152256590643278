import SelectAIModel from "@/modules/studio/features/environment/SelectAIModel";
import SelectOrCreateEnvironment from "@/modules/studio/features/environment/SelectOrCreateEnvironment";
import { useUserSession } from "@/session/UserSession";
import {
    ExecutionEnvironmentRef,
    RunDataStorageLevel,
} from "@becomposable/common";
import { AIModel, ModelOptions } from "@llumiverse/core";
import { Divider, InputList, NumberInput, Spinner } from "@reactik/components";
import { useCompositeState } from "@reactik/hooks";
import { ReactNode, useEffect, useState } from "react";
import PlaygroundState from "./PlaygroundState";
import clsx from "clsx";
import SelectRunDataLevel from "../configuration/SelectRunDataLevel";
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/16/solid";
import Popover from "@/components/popover/Popover";


export default function RunConfigurationEditor() {
    const { client } = useUserSession();
    const [isLoaded, setIsLoaded] = useState(false);
    const playgroundState = useCompositeState(PlaygroundState);
    const interaction = playgroundState.interaction;
    const [env, setEnv] = useState<ExecutionEnvironmentRef | undefined>(undefined);
    // Get ModelOptions properties from the interaction which extends ModelOptions.
    const [modelConfig, setModelConfig] = useState<ModelOptions | undefined>(
        {
            max_tokens: interaction.max_tokens,
            temperature: interaction.temperature,
            top_k: interaction.top_k,
            top_p: interaction.top_p,
            top_logprobs: interaction.top_logprobs,
            presence_penalty: interaction.presence_penalty,
            frequency_penalty: interaction.frequency_penalty,
            stop_sequence: interaction.stop_sequence
        });
    const [model, setModel] = useState<string | undefined>(interaction.model);
    const [run_data, setRunData] = useState<RunDataStorageLevel | undefined>(interaction.restriction);
    const [isAdvConfigOpen, setIsAdvConfigOpen] = useState(false);

    const onSelectEnv = (newEnv: ExecutionEnvironmentRef) => {
        if (newEnv.id === env?.id) return;
        setEnv(newEnv);
    };

    useEffect(() => {
        playgroundState.config = {
            max_tokens: modelConfig?.max_tokens,
            temperature: modelConfig?.temperature,
            top_k: modelConfig?.top_k,
            top_p: modelConfig?.top_p,
            top_logprobs: modelConfig?.top_logprobs,
            presence_penalty: modelConfig?.presence_penalty,
            frequency_penalty: modelConfig?.frequency_penalty,
            stop_sequence: modelConfig?.stop_sequence,
            run_data: run_data,
            model,
            environment: env?.id
        };
        playgroundState.env = env;
    }, [modelConfig, model, env, run_data, playgroundState]); // when playgroundState changes we need to update the config

    useEffect(() => {
        if (interaction.environment) {
            client.environments
                .retrieve(interaction.environment as string)
                .then((env) => {
                    setEnv(env);
                })
                .catch((err) => {
                    console.error("Failed to load execution environment", err);
                })
                .finally(() => setIsLoaded(true));
        } else {
            setIsLoaded(true);
        }
    }, [interaction.environment, client.environments]);

    const setAIModel = (model?: AIModel) => {
        setModel(model?.id);
    };

    const onChangeField = (name: string, value: any) => {
        setModelConfig({ ...modelConfig, [name]: value });
    };

    const onChangeRunData = (value: string) => {
        const run_data: RunDataStorageLevel = value as RunDataStorageLevel;
        setRunData(run_data);
        playgroundState?.config && (playgroundState.config.run_data = run_data);
    }

    const initPlaygroundRunData = () => { 
        playgroundState?.config && (playgroundState.config.run_data = interaction.restriction);
        setRunData(interaction.restriction);
        return playgroundState?.config?.run_data;
    }


    return isLoaded ? (
        <div className="flex w-full flex-col gap-6">
            <div className="mb-4">
                <div className="mb-2 text-gray-500 font-semibold text-sm">
                    Select an execution environment
                </div>
                <SelectOrCreateEnvironment value={env} onChange={onSelectEnv} selectFirst />
            </div>

            <div>
                <div className="mb-2 text-gray-500 font-semibold text-sm">
                    Select a Model
                </div>
                {env && (
                    <SelectAIModel
                        models={env?.enabled_models}
                        selected={model}
                        onSelect={setAIModel}
                        env={env}
                        allowSearch={true}
                        size="sm"
                    />
                )}
            </div>
            <div className="font-semibold flex items-center">
                <button onClick={() => { setIsAdvConfigOpen(!isAdvConfigOpen); }}
                className="mx-2 px-1">
                    {isAdvConfigOpen ? <ChevronUpIcon className='w-4 h-4' />
                        : <ChevronRightIcon className='w-4 h-4' />}
                </button>
                Advanced Configuration
            </div>
            {isAdvConfigOpen && (
                <div>
                    <FormItem label="Run Data">
                        <SelectRunDataLevel
                            value={run_data || initPlaygroundRunData()}
                            onChange={(value) => onChangeRunData(value)}
                        />
                    </FormItem>
                    <div className="grid grid-cols-2 gap-4">
                        <OptionPopoverPanel title={"Max Tokens"}
                            description={"Generation is stopped, once this limit is reached."}>
                            <FormItem label="Max Tokens">
                                <NumberInput
                                    name="max_tokens"
                                    value={modelConfig?.max_tokens}
                                    onChange={(value) => onChangeField("max_tokens", value ?? null)}
                                    min={0}
                                    step={100}
                                    noScroll
                                    placeholder="Model default"
                                />
                            </FormItem>
                        </OptionPopoverPanel>
                        <OptionPopoverPanel title={"Temperature"}
                            description={(<p>Controls the predictability vs. creativity in generated output. <br /> A higher temperature results in less predictable, more creative output.</p>)}>
                            <FormItem label="Temperature">
                                <NumberInput
                                    name="temperature"
                                    value={modelConfig?.temperature}
                                    onChange={(value) => onChangeField("temperature", value ?? null)}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    noScroll
                                    placeholder="Model default"
                                />
                            </FormItem>
                        </OptionPopoverPanel>
                        <OptionPopoverPanel title={"Top P"}
                            description={(<p>Limits the generation to the most likely tokens. <br /> Top P = 0.6, only choose from the most likely tokens whose cumulative probability is at least 60%.</p>)}>
                            <FormItem label="Top P">
                                <NumberInput
                                    name="top_p"
                                    value={modelConfig?.top_p}
                                    onChange={(value) => onChangeField("top_p", value ?? null)}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    noScroll
                                    placeholder="Model default"
                                />
                            </FormItem>
                        </OptionPopoverPanel>
                        <OptionPopoverPanel title={"Top K"}
                            description={(<p>Limits the generation to the most likely tokens. <br /> Top K = 5, only choose from the 5 most likely tokens.</p>)}>
                            <FormItem label="Top K">
                                <NumberInput
                                    name="top_k"
                                    value={modelConfig?.top_k}
                                    onChange={(value) => onChangeField("top_k", value ?? null)}
                                    min={0}
                                    step={1}
                                    noScroll
                                    placeholder="Model default"
                                />
                            </FormItem>
                        </OptionPopoverPanel>
                        <OptionPopoverPanel title={"Frequency Penalty"}
                            description={(<p>Penalises tokens for every time they have appeared in the output. <br /> A negative value encourages repetition.</p>)}>
                            <FormItem label="Frequency Penalty">
                                <NumberInput
                                    name="frequency_penalty"
                                    value={modelConfig?.frequency_penalty}
                                    onChange={(value) => onChangeField("frequency_penalty", value ?? null)}
                                    min={-2.0}
                                    max={2.0}
                                    step={0.1}
                                    noScroll
                                    placeholder="Model default"
                                />
                            </FormItem>
                        </OptionPopoverPanel>
                        <OptionPopoverPanel title={"Presence Penalty"}
                            description={(<p>Penalises tokens that have already appeared at least once in the output. <br /> A negative value encourages repetition.</p>)}>
                            <FormItem label="Presence Penalty">
                                <NumberInput
                                    name="presence_penalty"
                                    value={modelConfig?.presence_penalty}
                                    onChange={(value) => onChangeField("presence_penalty", value ?? null)}
                                    min={-2.0}
                                    max={2.0}
                                    step={0.1}
                                    noScroll
                                    placeholder="Model default"
                                />
                            </FormItem>
                        </OptionPopoverPanel>
                    </div>
                    <OptionPopoverPanel title={"Stop sequences"}
                        description={"A stop sequence is a string of characters which signals generation to stop if it is reached."}>
                        <FormItem label="Stop sequences">
                            <InputList value={modelConfig?.stop_sequence} onChange={(value) => onChangeField("stop_sequence", value)} />
                        </FormItem>
                    </OptionPopoverPanel>
                </div>
                )}
            </div>
    ) : (
        <div className="flex justify-center">
            <Spinner size="lg" />
        </div>
    );
}


interface FormItemProps {
    label: string;
    children: ReactNode;
    className?: string;
}

function FormItem({ label, className, children }: FormItemProps) {
    return (
        <div className={clsx("flex w-full flex-col pb-2 space-y-1", className)}>
            <label className="text-base font-medium text-gray-600">{label}</label>
            {children}
        </div>
    );
}
interface OptionPopoverPanelProps {
    title?: string;
    description?: ReactNode;
    children: React.ReactNode;
}
export function OptionPopoverPanel({ title, description, children }: OptionPopoverPanelProps) {
    return (
        <Popover strategy='fixed' placement='right-start' zIndex={100}>
            <Popover.Trigger click className="cursor-pointer w-full">
                {children}
            </Popover.Trigger>
            <Popover.Content>
                <div className="bg-indigo-50 rounded-md shadow-md py-2">
                    <div className='px-3 pb-1 text-base font-semibold'>{title}</div>
                    <Divider className="bg-indigo-100" />
                    <div className='px-3 pt-1 pb-1'>{description}</div>
                </div>
            </Popover.Content>
        </Popover>
    )
}
