import InteractionViewState from "@/modules/studio/features/interaction/InteractionViewState";
import EditorLayout from "@/modules/studio/features/prompt/editor/EditorLayout";
import { useUserSession } from "@/session/UserSession";
import useUXTracking from "@/session/useUXTracking";
import { ExecutionRun } from "@becomposable/common";
import { PlayIcon } from "@heroicons/react/20/solid";
import type { JSONObject } from "@llumiverse/core";
import { Button, useToast } from "@reactik/components";
import { useCompositeState, useSetCompositeStateProperty } from "@reactik/hooks";
import { useMemo, useState } from "react";
import PlaygroundState, { ConfigValidationError } from "./PlaygroundState";
import RunConfigurationEditor from "./RunConfigurationEditor";
import RunPayloadEditor from "./RunPayloadEditor";
import { SchemaOverrideEditor } from "./SchemaOverrideEditor";



export default function ExecutionRequestView() {
    const sharedState = useCompositeState(InteractionViewState);

    const onTestDataChange = () => {
        if (sharedState) {
            sharedState.isDirty.value = true;
        }
    };

    return (
        <EditorLayout title="Execution Payload"
            actions={<RunButton />}
        >
            <RunConfigurationEditor />
            <SchemaOverrideEditor />
            <RunPayloadEditor onChange={onTestDataChange} />
            {sharedState && <SaveProvider sharedState={sharedState} />}
        </EditorLayout>
    )
}

interface SaveProviderProps {
    sharedState: InteractionViewState;
}
function SaveProvider({ sharedState }: SaveProviderProps) {
    const playgroundState = useCompositeState(PlaygroundState);
    const { client } = useUserSession();

    const onSaveTestData = useMemo(() => () => {
        return client.interactions.update(playgroundState.interaction.id, {
            test_data: playgroundState.payload.value as JSONObject,
        });
    }, [playgroundState.interaction]);

    useSetCompositeStateProperty(sharedState.save, onSaveTestData);

    return null;
}

function RunButton() {
    const toast = useToast();
    const [isRunning, setIsRunning] = useState(false);
    const playgroundState = useCompositeState(PlaygroundState);
    const { trackEvent } = useUXTracking();

    const run = () => {
        setIsRunning(true);
        playgroundState
            .run()
            .then((run: ExecutionRun) => {
                trackEvent("run_interaction", {
                    zone: 'playground',
                    model: run.modelId,
                    status: run.status
                });
            }).catch((err: any) => {
                let title, description;
                if (err instanceof ConfigValidationError) {
                    title = "Invalid configuration";
                    description = err.message;
                } else {
                    title = "Failed to run interaction";
                    description = err.message;
                }
                toast({
                    status: "error",
                    title,
                    description,
                    duration: 6000,
                });
            })
            .finally(() => {
                setIsRunning(false);
            });
    };


    return (
        <Button isLoading={isRunning} onClick={run}>
            <PlayIcon className="w-5 h-5" />
            Run
        </Button>
    )
}