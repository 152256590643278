import Env from '@/env';
import { AuthTokenPayload } from '@becomposable/common';
import { logEvent } from "firebase/analytics";
import { analytics } from "./auth/firebase";

export default function useUXTracking() {

    //identify user in monitoring and UX systems
    const tagUserSession = async (user?: AuthTokenPayload) => {
        const signupData = window.localStorage.getItem("composableSignupData");
        const signupInfo = signupData ? JSON.parse(signupData) : undefined;
        if (!user) {
            console.error('No user found -- skipping tagging');
            return;
        }

        if (signupData) {
            window.localStorage.removeItem("composableSignupData");
        }
    }

    //send event to analytics and UX systems
    const trackEvent = (eventName: string, eventProperties?: any) => {

        if (!Env.isProd) {
            console.debug('track event', eventName, eventProperties);
        }

        //GA via firebase
        logEvent(analytics, eventName, { ...eventProperties, debug_mode: !Env.isProd });

    }

    return {
        tagUserSession,
        trackEvent
    }

}