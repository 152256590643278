import { OAuthProvider, signInWithRedirect } from "firebase/auth";
import { firebaseAuth } from "../../session/auth/firebase";

interface OIDCSignInButtonProps {
    redirectTo?: string;
    providerId: string;
    label: string;
    icon?: string;
}
export default function OIDCSignInButton({ redirectTo, providerId, label, icon }: OIDCSignInButtonProps) {

    const signIn = () => {
        const baseUrl = "https://dengenlabs.firebaseapp.com/__/auth/handler";
        let redirectPath = baseUrl + window.location.pathname;
        if (redirectPath[0] !== "/") {
            redirectPath = "/" + redirectPath;
        }
        const provider = new OAuthProvider(providerId);
        signInWithRedirect(firebaseAuth, provider);
    };

    return (
        <button
            onClick={signIn}
            className="my-2 flex w-52 gap-2 rounded-lg border border-slate-200 px-4 py-2 text-slate-700 transition duration-150  dark:text-slate-300 hover:border-slate-400 hover:text-slate-900 dark:hover:text-slate-200 hover:shadow"
        >
            { icon && <img
                className="h-6 w-6"
                src={ icon }
                loading="lazy"
            /> }
            <span className="text-sm font-semibold">{ label }</span>
        </button>
    );
}
